@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  overflow-x: hidden;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --heavy-metal: rgba(43, 43, 43, 1);
  --magenta-fuchsia: rgba(255, 0, 255, 1);
  --yellow-sunshine: rgba(255, 241, 0, 1);
  --white: rgba(255, 255, 255, 1);

  --font-size-xxxxs: 8px;
  --font-size-xxxs: 12px;
  --font-size-xxs: 14px;
  --font-size-xs: 22px;
  --font-size-s: 38px;
  --font-size-m: 40px;
  --font-size-l: 46px;
  --font-size-xl: 80px;
  --font-size-xxl: 300px;

  --font-family-outfit: 'Outfit';
  --font-family-poppins: 'Poppins';
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-outfit);
}
